class StartSearch {
	constructor( $el ) {
		this.$el = {
			root: $el,
			newSearch: $el.querySelector( '.js-start-search-new' ),
			savedSearch: $el.querySelector( '.js-start-search-saved' ),
			partLabel: $el.querySelector( '.js-start-search-part-number' ),
		};

		this.classNames = {
			hide: 'd-none',
		};

		this.init();
	}

	init() {
		const savedSearch = JSON.parse( window.localStorage.getItem( 'savedSearch' ) );

		if ( savedSearch ) {
			if ( savedSearch.marketPlaceDescription ) {
				const text = `${ this.$el.partLabel.getAttribute( 'data-label' ) } ${
					savedSearch.marketPlaceDescription
				}`;
				this.$el.partLabel.textContent = text;
			} else {
				this.$el.partLabel.textContent = this.$el.partLabel.getAttribute( 'data-default-label' );
			}

			this.$el.newSearch.classList.add( this.classNames.hide );
			this.$el.savedSearch.classList.remove( this.classNames.hide );
		}
	}
}

document.addEventListener( 'DOMContentLoaded', () => {
	const $startSearch = document.querySelector( '.js-start-search' );
	if ( $startSearch ) {
		new StartSearch( $startSearch );
	}
} );
