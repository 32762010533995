/**
 * File navigation.js.
 *
 * Handles toggling the navigation menu for small screens and enables TAB key
 * navigation support for dropdown menus.
 */
( function() {
	const searchToggleHiddenClass = 'm-header__search-toggle-container--hidden';

	const overlay = document.querySelector( '.a-overlay' );
	const siteNavigation = document.getElementById( 'site-navigation' );
	const navigationContainer = document.querySelector( '.m-header__navigation-container' );
	const searchForm = document.querySelector( '.m-header__search-form' );
	const logoContainer = document.querySelector( '.m-header__logo-container' );
	const searchToggleContainer = document.querySelector( '.m-header__search-toggle-container' );

	// Return early if the navigation doesn't exist.
	if ( ! siteNavigation ) {
		return;
	}

	const toggleButton = document.querySelector( '.m-header__menu-toggle' );
	const searchToggleButton = document.querySelector( '.m-header__search-toggle' );
	const searchField = document.querySelector( '.m-header__search-field' );

	// Return early if the toggleButton doesn't exist.
	if ( 'undefined' === typeof toggleButton ) {
		return;
	}

	const menu = siteNavigation.getElementsByTagName( 'ul' )[ 0 ];

	// Hide menu toggle toggleButton if menu is empty and return early.
	if ( 'undefined' === typeof menu ) {
		toggleButton.style.display = 'none';
		return;
	}

	if ( ! menu.classList.contains( 'nav-menu' ) ) {
		menu.classList.add( 'nav-menu' );
	}

	// Toggle the .toggled class and the aria-expanded value each time the toggleButton is clicked.
	toggleButton.addEventListener( 'click', toggleHamburgerButton );
	searchToggleButton.addEventListener( 'click', toggleSearchForm );

	// Remove the .toggled class and set aria-expanded to false when the user clicks outside the navigation.
	document.addEventListener( 'click', function( event ) {
		const mediaQueryList = window.matchMedia( '(min-width: 768px)' );
		if ( mediaQueryList.matches ) {
			const isClickInside = siteNavigation.contains( event.target ) ||
			( event.target === navigationContainer || navigationContainer.contains( event.target ) ) ||
			( event.target === toggleButton || toggleButton.contains( event.target ) );

			if ( ! isClickInside ) {
				const focusedLink = document.querySelector( '.m-header__submenu.focus' );
				if ( focusedLink ) {
					focusedLink.classList.remove( 'focus' );
					overlay.classList.remove( 'a-overlay--is-visible' );
				}
				navigationContainer.classList.remove( 'toggled' );
				toggleButton.setAttribute( 'aria-expanded', 'false' );
			}
		}
	} );

	// Get all the link elements with children within the menu.
	const linksWithChildren = menu.querySelectorAll( '.menu-item-has-children > a, .page_item_has_children > a' );

	// Toggle focus each time a menu link with children receive a touch event.
	for ( const link of linksWithChildren ) {
		link.addEventListener( 'click', toggleFocus, false );
	}

	const ssoLoginToggleButton = document.querySelector( '.m-header__sso-login-toggle' );

	if (ssoLoginToggleButton) {
		ssoLoginToggleButton.addEventListener( 'click', triggerToggleMenu );
	}

	function triggerToggleMenu() {
		toggleButton.click();
	}

	/**
	 * This method is on charge of showing or hidding the search form
	 */
	function toggleSearchForm() {
		searchForm.classList.toggle( 'm-header__search-form--mobile-visible' );
		searchToggleButton.classList.toggle( 'm-header__search-toggle--is-open' );
		if ( searchForm.classList.contains( 'm-header__search-form--mobile-visible' ) && searchField ) {
			searchField.focus();
		}
	}

	/**
	 * Toogle method to add or remote the need clases to show the menu and
	 * convert the hamburger button into the close buttom for the menu
	 */
	function toggleHamburgerButton() {
		document.body.classList.toggle( 'a-body--scroll-disable' );

		navigationContainer.classList.toggle( 'toggled' );
		if ( toggleButton.getAttribute( 'aria-expanded' ) === 'true' ) {
			toggleButton.setAttribute( 'aria-expanded', 'false' );
			overlay.classList.remove( 'a-overlay--is-visible' );
			toggleButton.classList.remove( 'js-is-open' );
			logoContainer.classList.remove( 'js-is-open' );
			searchToggleContainer.classList.remove( searchToggleHiddenClass );
		} else {
			toggleButton.setAttribute( 'aria-expanded', 'true' );
			toggleButton.classList.add( 'js-is-open' );
			logoContainer.classList.add( 'js-is-open' );
			overlay.classList.add( 'a-overlay--is-visible' );
			searchToggleContainer.classList.add( searchToggleHiddenClass );
		}

		if ( searchForm.classList.contains( 'm-header__search-form--mobile-visible' ) ) {
			toggleSearchForm();
		}
	}

	/**
	 * Sets or removes .focus class on an element.
	 *
	 * @param {Object} event
	 */
	function toggleFocus( event ) {
		event.preventDefault();
		const menuItem = this.parentNode;
		for ( const link of menuItem.parentNode.children ) {
			if ( menuItem !== link ) {
				link.classList.remove( 'focus' );
			}
		}
		menuItem.classList.toggle( 'focus' );

		// validate if we need to show the overlay
		const isActiveSubmenu = document.querySelectorAll( '.menu-item.focus' ).length > 0;
		if ( isActiveSubmenu ) {
			overlay.classList.add( 'a-overlay--is-visible' );
		} else {
			overlay.classList.remove( 'a-overlay--is-visible' );
		}
	}
}() );
