document.addEventListener( 'DOMContentLoaded', () => {
	document.querySelectorAll( '.js-go-back-cta' ).forEach( ( $el ) => {
		if ( history.length > 1 ) {
			$el.addEventListener( 'click', () => {
				history.back();
			} );
			const hideClass = 'invisible';
			$el.classList.remove( hideClass );
		}
	} );

	document.querySelectorAll( '.js-close-details' ).forEach( ( $el ) => {
		$el.addEventListener( 'click', () => {
			window.close();
		} );
	} );
} );
