/**
 * File filtered-grid.js.
 *
 * Handles the event for the filtered grid component
 */
( function() {
	const showAllTriggers = document.querySelectorAll(
		'.js-filtered-grid-show-all-category-options, .js-filtered-grid-show-less-category-options',
	);

	if ( ! showAllTriggers ) {
		return;
	}

	for ( const trigger of showAllTriggers ) {
		trigger.addEventListener( 'click', toggleAllCategoryOptions );
	}

	function toggleAllCategoryOptions( e ) {
		const contentWrapper = e.target.closest( '.m-accordion__content' );
		if ( ! contentWrapper ) {
			return;
		}

		const categoryOptions = contentWrapper.querySelector(
			'.m-filtered-grid__category-options',
		);
		const showMoreCta = contentWrapper.querySelector(
			'.m-accordion__show-more-cta',
		);
		const showLessCta = contentWrapper.querySelector(
			'.m-accordion__show-less-cta',
		);
		if ( ! categoryOptions ) {
			return;
		}
		showMoreCta.classList.toggle( 'hidden' );
		showLessCta.classList.toggle( 'hidden' );
		categoryOptions.classList.toggle(
			'm-filtered-grid__category-options--show-all',
		);
	}

	const filterToggle = document.querySelector( '.m-search__advanced-filter-toggle' );

	if ( ! filterToggle ) {
		return;
	}

	filterToggle.addEventListener( 'click', () => {
		filterToggle.classList.toggle( 'show' );

		const filterWrapper = document.querySelector( '.m-search__advanced-filter-wrapper' );
		filterWrapper.classList.toggle( 'show' );
	} );
}() );
