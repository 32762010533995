/**
 * File navigation.js.
 *
 * Handles toggling the navigation menu for small screens and enables TAB key
 * navigation support for dropdown menus.
 */
import { trackAccordionClick } from '../analytics/cms';

( function() {
	const accordionsTriggers = document.querySelectorAll( '.m-accordion__trigger' );

	for ( const trigger of accordionsTriggers ) {
		trigger.addEventListener( 'click', toggleAccordion );
	}

	function toggleAccordion() {
		const isOpened = this.getAttribute( 'aria-expanded' ) === 'true';
		const content = this.nextSibling;

		this.setAttribute( 'aria-expanded', `${ ! isOpened }` );
		if ( content ) {
			content.classList.toggle( 'm-accordion__content--hidden' );
		}

		if ( ! isOpened ) {
			trackAccordionClick( this.querySelector( '.m-accordion__title' ).innerText );
		}
	}
}() );
