export const htmlToElement = ( htmlString ) => {
	const template = document.createElement( 'template' );
	htmlString = htmlString.trim();
	template.innerHTML = htmlString;
	return template.content.firstChild;
};

export const showLoading = () => {
	const overlay = document.querySelector( '.a-overlay' );
	overlay.classList.add( 'a-overlay--is-visible' );
	overlay.classList.add( 'a-overlay--with-spinner' );
};

export const hideLoading = () => {
	const overlay = document.querySelector( '.a-overlay' );
	overlay.classList.remove( 'a-overlay--is-visible' );
	overlay.classList.remove( 'a-overlay--with-spinner' );
};
