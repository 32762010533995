class ToolsList {
	static animationDelay = 1000;
	static defaultHeight = 76;

	constructor( $el ) {
		this.$el = {
			root: $el,
			showMore: $el.querySelector( '.js-eng-tools-show-more' ),
			collapsedItems: $el.querySelectorAll( '.js-eng-tools-collapsed-item' ),
			links: $el.querySelectorAll( '.js-eng-tools-link' ),
		};

		this.classNames = {
			hide: 'd-none',
		};

		this.linkHeight = $el.links
			? $el.links[ 0 ].offsetHeight
			: ToolsList.defaultHeight;

		this._animationTimer = null;

		this.bindEvents();
	}

	bindEvents() {
		if ( this.$el.showMore ) {
			this.$el.showMore.addEventListener( 'click', () => {
				this.$el.collapsedItems.forEach( ( el ) => {
					el.classList.remove( this.classNames.hide );
				} );

				this.$el.showMore.classList.add( this.classNames.hide );
			} );
		}

		this.$el.links.forEach( ( link ) => {
			link.addEventListener( 'mouseenter', () => {
				clearTimeout( this._animationTimer );

				const newHeight =
					this.linkHeight +
					link.querySelector( '.js-eng-tools-link-description' ).offsetHeight;

				this._animationTimer = setTimeout( () => {
					link.style.height = `${ newHeight }px`;
				}, ToolsList.animationDelay );
			} );

			link.addEventListener( 'mouseleave', () => {
				clearTimeout( this._animationTimer );

				link.style.height = `${ this.linkHeight }px`;
			} );
		} );
	}
}

document.addEventListener( 'DOMContentLoaded', () => {
	const $toolsList = document.querySelector( '.js-eng-tools' );
	if ( $toolsList ) {
		new ToolsList( $toolsList );
	}
} );
