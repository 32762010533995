import { htmlToElement } from './utils';

const generatePageButton = ( pageNumber, currentPage, searchCallback, pageSize ) => {
	const pageButton = htmlToElement( `
		<button class="m-search__pagination-btn ${ pageNumber === currentPage ? 'm-search__pagination-btn--current' : '' }">
			<span class="m-search__pagination-btn-number">
				${ pageNumber }
			</span>
		</button>
	` );
	if ( pageNumber !== currentPage ) {
		pageButton.addEventListener( 'click', () => {
			searchCallback( pageNumber, pageSize );
		} );
	}
	return pageButton;
};

export const generatePaginationLabel = ( pageNumber, pageSize, totalItems ) => {
	// Calculate the start and end indexes of the current page
	const startIndex = ( ( pageNumber - 1 ) * pageSize ) + 1;
	const endIndex = Math.min( startIndex + pageSize - 1, totalItems );

	// Generate the pagination label string
	return `Viewing ${ startIndex }-${ endIndex } of ${ totalItems }`;
};

export const generatePaginationButtons = ( pageSize, pageNumber, totalItems, paginationWrapper, searchCallback ) => {
	const totalPages = Math.ceil( totalItems / pageSize );

	paginationWrapper.innerHTML = '';

	// Previous button
	const prevButton = htmlToElement( `
		<button
			class="m-search__pagination-btn m-search__pagination-btn--prev ${ pageNumber === 1 ? 'm-search__pagination-btn--disabled' : '' } icon-toggle m-icon--circular-wrapper">
			<span class="icon-caret-left" aria-hidden="true"></span>
		</button>
	` );
	prevButton.addEventListener( 'click', () => {
		searchCallback( pageNumber - 1, pageSize );
	} );
	paginationWrapper.appendChild( prevButton );

	if ( totalPages <= 5 ) {
		// All pages shown
		for ( let i = 1; i <= totalPages; i++ ) {
			const pageButton = generatePageButton( i, pageNumber, searchCallback, pageSize );
			paginationWrapper.appendChild( pageButton );
		}
	} else {
		// Show ellipse before and after current page
		let firstButton = 1;
		let lastButton = totalPages;
		if ( pageNumber > 2 ) {
			const pageButton = generatePageButton( 1, pageNumber, searchCallback, pageSize );
			paginationWrapper.appendChild( pageButton );

			firstButton = pageNumber - 1;
			const ellipseBefore = htmlToElement( `
				<span class="m-search__pagination-ellipse"> ... </span>
			` );
			paginationWrapper.appendChild( ellipseBefore );
		}
		if ( pageNumber < totalPages - 2 ) {
			lastButton = pageNumber + 2;
		}
		for ( let i = firstButton; i <= lastButton; i++ ) {
			const pageButton = generatePageButton( i, pageNumber, searchCallback, pageSize );
			paginationWrapper.appendChild( pageButton );
		}
		if ( pageNumber < totalPages - 2 ) {
			const ellipseBefore = htmlToElement( `
				<span class="m-search__pagination-ellipse"> ... </span>
			` );
			paginationWrapper.appendChild( ellipseBefore );
			const pageButton = generatePageButton( totalPages, pageNumber, searchCallback, pageSize );
			paginationWrapper.appendChild( pageButton );
		}
	}

	// Next button
	const nextButton = htmlToElement( `
		<button
			class="m-search__pagination-btn m-search__pagination-btn--next ${ pageNumber === totalPages ? 'm-search__pagination-btn--disabled' : '' } icon-toggle m-icon--circular-wrapper">
			<span class="icon-caret-right" aria-hidden="true"></span>
		</button>
	` );
	nextButton.addEventListener( 'click', () => {
		searchCallback( pageNumber + 1, pageSize );
	} );
	paginationWrapper.appendChild( nextButton );
};
