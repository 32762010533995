document.addEventListener( 'DOMContentLoaded', () => {
	document.querySelectorAll( '.js-share-cta' ).forEach( ( $el ) => {
		$el.addEventListener( 'click', () => {
			const $message = $el.querySelector( '.js-share-cta-message' );
			const url = window.location.href;
			const DELAY = 3000;
			const hideClass = 'd-none';

			navigator.clipboard.writeText( url ).then(
				() => {
					$message.classList.remove( hideClass );
					$el.setAttribute( 'disabled', true );

					setTimeout( () => {
						$message.classList.add( hideClass );
						$el.removeAttribute( 'disabled' );
					}, DELAY );
				},
				() => {
					// eslint-disable-next-line no-console
					console.error( "Can't copy url, try copy manually" );
				},
			);
		} );
	} );

	document.querySelectorAll( '.js-print-cta' ).forEach( ( $el ) => {
		$el.addEventListener( 'click', () => window.print() );
	} );
} );
