(function () {
	const modalTriggers = document.querySelectorAll('[controls-dialog]');
	let overlay = document.querySelector('.a-overlay');

	for (const trigger of modalTriggers) {
		// Handle the dropdown toggle button click event
		trigger.addEventListener('click', (e) => {
			const dialogId = e.target.getAttribute("controls-dialog");
			showDialog(dialogId);
			e.stopPropagation();
			e.preventDefault();
		});
	}

	const showDialog = (dialogId) => {
		let overlay = document.querySelector('.a-overlay');
		const modal = document.getElementById(dialogId);
		// Show the modal
		modal.classList.add('show');
		overlay.classList.add("a-overlay--is-visible");

		const positionOffset = 100;
		const top = window.scrollY + positionOffset;
		// Set the top position of the modal
		modal.style.top = `${top}px`;

		// Get the button that closes the modal
		const closeModalButton = modal.querySelector('.m-modal__close');
		// When the user clicks the close modal button, hide the modal
		closeModalButton.addEventListener('click', () => {
			closeDialog(dialogId);
		});

	}

	const closeDialog = (dialogId) => {
		const modal = document.getElementById(dialogId);
		let overlay = document.querySelector('.a-overlay');
		modal.classList.remove('show');
		overlay.classList.remove("a-overlay--is-visible");
	}

	window._timken = {
		...window._timken,
		showDialog,
		closeDialog
	}

	// When the user clicks outside the modal, hide the modal
	window.addEventListener('click', (event) => {
		const openedDialog = document.querySelector(".m-modal__wrapper.show");
		const dialogContent = openedDialog?.querySelector(".m-modal");
		if (openedDialog) {
			if (!dialogContent.contains(event.target) && !event.target.getAttribute("controls-dialog")
				&& event.target?.type !== "submit") {
				openedDialog.classList.remove('show');
				overlay.classList.remove("a-overlay--is-visible");
			}
		}
	});

	// When the user presses the Escape key, hide the modal
	window.addEventListener('keydown', (event) => {
		if (event.key === 'Escape') {
			const openedDialog = document.querySelector(".m-modal__wrapper.show");
			if (openedDialog) {
				openedDialog.classList.remove('show');
				overlay.classList.remove("a-overlay--is-visible");
			}
		}
	});
}());
