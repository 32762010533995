class PopularTools {
	constructor( $el ) {
		this.$el = {
			root: $el,
			container: $el.querySelector( '.js-popular-tools-container' ),
			defaultTitle: $el.querySelector( '.js-popular-tools-title-default' ),
			personalizedTitle: $el.querySelector(
				'.js-popular-tools-title-personalized',
			),
		};

		this.classNames = {
			hide: 'd-none',
		};

		this.allTools = JSON.parse(
			this.$el.container.getAttribute( 'data-engineering-tools' ),
		);

		this.popularTools = [];

		this.init();
	}

	init() {
		const usedTools = JSON.parse( window.localStorage.getItem( 'popularTools' ) );
		const defaultTools = JSON.parse(
			this.$el.container.getAttribute( 'data-default-tools' ),
		).map( ( id ) => `${ id }` );
		let toolsId;

		if ( usedTools && usedTools.length ) {
			toolsId = usedTools;
			this.$el.personalizedTitle.classList.remove( this.classNames.hide );
			this.$el.defaultTitle.classList.add( this.classNames.hide );
		} else {
			toolsId = defaultTools;
		}

		const toolsMap = new Map();

		for ( const tool of this.allTools ) {
			toolsMap.set( `${ tool.ID }`, tool );
		}

		for ( const id of toolsId ) {
			const tool = toolsMap.get( id );

			if ( tool ) {
				this.popularTools.push( tool );
			}
		}

		this.renderToolCards();
	}

	renderToolCards() {
		this.popularTools.forEach( ( tool ) => {
			const { ID, icon, title, url } = tool;
			const $card = document.createElement( 'a' );
			$card.classList.add( 'a-tile-cta' );
			$card.setAttribute( 'href', url );
			$card.setAttribute( 'id', `popular_tool_${ ID }` );

			$card.innerHTML = `
				<div class="a-tile-cta__icon">
					<span class="icon-${ icon }" aria-hidden="true"></span>
				</div>
				<div class="a-tile-cta__label">${ title }</div>
			`;

			this.$el.container.append( $card );
		} );
	}

	static enqueueTool( id ) {
		if ( ! id ) {
			return;
		}
		id = String(id);
		const TOOLS_LEN = 3;
		const tools = JSON.parse( window.localStorage.getItem( 'popularTools' ) ) || [];

		if ( ! tools.includes( id ) ) {
			if ( tools.length === TOOLS_LEN ) {
				tools.shift();
			}
			tools.push( id );
		}

		window.localStorage.setItem( 'popularTools', JSON.stringify( tools ) );
	}
}

document.addEventListener( 'DOMContentLoaded', () => {
	const $tool = document.querySelector( '[data-engineering-tool-id]' );
	if ( $tool ) {
		PopularTools.enqueueTool( $tool.getAttribute( 'data-engineering-tool-id' ) );
	}
} );

document.addEventListener( 'DOMContentLoaded', () => {
	const $popularTools = document.querySelector( '.js-popular-tools' );
	if ( $popularTools ) {
		new PopularTools( $popularTools );
	}
} );

// Listen for Syber Bearing System designer links as it's external to WordPress
document.addEventListener( 'DOMContentLoaded', () => {
	const $elementsWithPopularToolLinks = document.querySelectorAll('.js-has-popular-tool-link');
	$elementsWithPopularToolLinks.forEach( element => {
		element.addEventListener('click', event => {
			const timkenSyberBearingSystemDesigner = window._timken.engineeringToolLinks['timken-syber-bearing-system-designer'];
			if ( typeof timkenSyberBearingSystemDesigner === "undefined") {
				return;
			}
			const $closestLink = event.target.closest('a');
			if ( ! $closestLink ) {
				return;
			}
			const isSyberLinkClicked = $closestLink.href === timkenSyberBearingSystemDesigner.url
			if ( isSyberLinkClicked ) {
				PopularTools.enqueueTool( timkenSyberBearingSystemDesigner.ID );
			}
		});
	});
} );
