/**
 * File navigation.js.
 *
 * Handles toggling the navigation menu for small screens and enables TAB key
 * navigation support for dropdown menus.
 */
( function() {
	const tabTriggers = document.querySelectorAll( '.m-tab-content__toggle' );

	for ( const trigger of tabTriggers ) {
		// show content for initial selected tab Content
		if ( trigger.getAttribute( 'aria-selected' ) === 'true' ) {
			showTabContent( trigger );
			trigger.classList.add( 'm-tab-content__toggle--active' );
		}
		trigger.addEventListener( 'click', toggleTabContent );
	}

	function scrollToActiveTabItem( tabTriggerElement ) {
		tabTriggerElement.scrollIntoView( {
			behavior: 'smooth',
			block: 'nearest',
			inline: 'center',
		} );
	}

	function showTabContent( tabTriggerElement ) {
		const tabPanelId = tabTriggerElement.getAttribute( 'aria-controls' );
		const tabPanel = document.getElementById( tabPanelId );
		tabPanel.classList.add( 'm-tab-content__content--show' );
	}

	function toggleTabContent() {
		const targetPanelId = this.getAttribute( 'aria-controls' );
		const tabContent = this.closest( '.m-tab-content' );
		const tabTitleTriggers = tabContent.querySelectorAll( '.m-tab-content__toggle' );

		for ( const trigger of tabTitleTriggers ) {
			trigger.setAttribute( 'aria-selected', 'false' );
			trigger.classList.remove( 'm-tab-content__toggle--active' );
		}
		this.setAttribute( 'aria-selected', 'true' );
		this.classList.add( 'm-tab-content__toggle--active' );
		scrollToActiveTabItem( this );

		const tabContentPanels = tabContent.querySelectorAll( '.m-tab-content__content' );
		for ( const tabContentPanel of tabContentPanels ) {
			if ( targetPanelId === tabContentPanel.id ) {
				tabContentPanel.classList.add( 'm-tab-content__content--show' );
			} else {
				tabContentPanel.classList.remove( 'm-tab-content__content--show' );
			}
		}
	}
}() );
