import Swiper, { Pagination } from 'swiper';

class PartsDetails {
	constructor( $el ) {
		this.$el = {
			root: $el,
			gallery: $el.querySelector( '.js-parts-image-slider' ),
		};

		this.init();
	}

	init() {
		if ( this.$el.gallery ) {
			this.imageSlider = new Swiper( this.$el.gallery, {
				modules: [ Pagination ],
				centeredSlides: true,
				pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
					clickable: true,
				},
			} );
		}

		const $note = document.querySelector( '.js-engineering-tools-notes' );
		const $noteLinks = document.querySelectorAll( '.t-engineering-tools__results-link' );

		if ($note) {
			$noteLinks?.forEach(function(el) {
				el.classList.remove( 'd-none' );
				el.addEventListener("click", function(e){
					e.preventDefault();
					let offset = 240;
					if (window.innerWidth < 768) {
						offset = 80;
					}
					let $noteTop = document.querySelector( '.js-engineering-tools-notes' ).offsetTop - offset;
					window.scrollTo({
						top: $noteTop,
						behavior: "smooth"
					});
				});
			});
		}
	}
}

document.addEventListener( 'DOMContentLoaded', () => {
	const $partsDetailsPage = document.querySelector( '.js-parts-details' );
	if ( $partsDetailsPage ) {
		new PartsDetails( $partsDetailsPage );
	}
} );
