import { trackHeroCarouselClick  } from '../analytics/cms';

jQuery(function ($) {
	let $banner = $('.c-home-banner-wrapper');
	let $slider = $('.c-home-banner-slider');
	let classNames = {
		collapsed: 'c-home-banner--collapsed',
		hide: 'd-none'
	};

	$banner.find('a').on('click', function () {
		let title = $(this).closest('.c-home-banner').find('.c-home-banner__title').html();
		let slide = 1;

		if ($slider.length) {
			slide = parseInt($(this).closest('.slick-slide').data('slick-index')) + 1;
		}

		trackHeroCarouselClick({
			details: "Hero Carousel | " + title.trim() + " | " + slide ,
			name: $(this).text().trim(),
			href: $(this).attr('href')
		});
	});

	if ($slider.length) {
		$slider.slick({
			dots: true,
			adaptiveHeight: true,
			prevArrow: '<button type="button" class="slick-prev icon-arrow-left"><span>Previous</span></button>',
			nextArrow: '<button type="button" class="slick-next icon-arrow-right"><span>Next</span></button>',
			autoplay: true,
			autoplaySpeed: 5000,
			pauseOnFocus: false,
			pauseOnHover: false,
			infinite: true
		});

		$slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
			if ($slider.find('#slick-slide0'+nextSlide).find('.c-home-banner').hasClass('Dark')) {
				$slider.find('.slick-dots').addClass('dark');
			} else {
				$slider.find('.slick-dots').removeClass('dark');
			}
		});
	}

	$banner.find('.js-home-banner-hide, .js-home-banner-show').on('click', function () {
		$banner.find('.js-home-banner').toggleClass(classNames.collapsed);
		$banner.find('.js-home-banner-show, .js-home-banner-hide').toggleClass(classNames.hide);

		if ($slider.length) {
			$slider.slick('refresh');
		}
	});
});
